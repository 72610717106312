
import HttpService from '../services/HttpService';

const v1ApiUrls = process.env.REACT_APP_API_ADDRESS;

const generalUrl = process.env.REACT_APP_GENERAL_API_ADDRESS



export const endpoints = {
  financialUser: '/financial-institution-users',
  logout: '/financial-institution-users/logout',
  financialinstitution: '/financialinstitution',
  financialDetail: '/discountapplication',
  sessions: '/financial-institution-users/authenticate',
  forgetPassword: '/financial-institution-users/forgot-password',
  expiredPassword: '/financial-institution-users/expired-password',
  codePassword: '/otp/validate',
  corporations: '/corporations',
  fundsCorporations: '/funds/corporations',
  fundsCorporations2: '/funds/corporations?fundType=2',
  fundsActivities: 'funds/activities/corporations',
  fundsOffers: '/funds/offers',
  corporationsPasswords: '/corporations/passwords',
  corporationsForgetPassword: '/corporations/forget-password',
  funds: '/funds',
  smeUsers: '/sme-users',
  preSmeUserRegistiration: '/sme-users/pre-registration',
  fundHistory: '/funds/sme-users',
  sendResetPasswordCode: '/sme-users/send-reset-password',
  resetPassword: '/sme-users/reset-password',
  subscriptions: '/subscriptions',
  usageInfo: '/subscriptions/usage-info/sme-users',
  createSubscription: '/subscriptions/in-app/sme-users',
  uploadImage: '/funds/images',
  playerId: '/sme-users/player-id',
  provinces: '/provinces',
  configurations: '/configurations',
  validateTaxId: '/validate-tax-id',
  banks: '/banks',
  updateTaxId: '/sme-users/tax-id',
  estimatedOffer: '/funds/estimated-offer',
  updateProvince: '/sme-users/province',
  financialData: '/sme-users/financial-data', 
  invoicePayment :'invoice/pre-payment',
  commissionInterest : '/rates',
  setInterestRate:'/rates',
  buyerList:'/financial-institutions/buyers/titles',
  invoicePay:'invoice/pay',
  passwordUpdate:'financial-institution-users/reset-password',
  getCreatePassword: '/financial-institution-users/password',

  getDueInvoice : (filters :string,  pageNumber: number, pageSize: number) =>
    `/invoice/upcoming-pay-date-invoices?${filters ? `${filters}` : ''}pageNumber=${pageNumber}&pageSize=${pageSize}`,
  
  getPaidInvoice : (filters :string,  pageNumber: number, pageSize: number) =>
    `/invoice/pre-paid-invoices?${filters ? `${filters}` : ''}pageNumber=${pageNumber}&pageSize=${pageSize}`,

  getBuyerList : ( filters:string, pageNumber: number, pageSize: number) =>
    `/financial-institutions/buyers?pageNumber=${pageNumber}&pageSize=${pageSize}&searchQuery=${filters}`,

  getCommissionInterest : ( buyerId: string) => `/rates?buyer-id=${buyerId}`,
  discountApplicationXlsx : ( discountApplicationId: string) => `/discount-offer/${discountApplicationId}/xlsx`,

  corporationsUser: (finanUserId: string) => `/financial-institution-users/${finanUserId}/password`,
  // getCreatePassword: (passwordUser: string) => `/financial-institution-users/${passwordUser}/password`,
  getFundDetailEndpoint: (fundId: string) => `/funds/${fundId}/corporations`,
  getFundViewedByEndpoint: (fundId: string) => `/funds/${fundId}/corporations/viewed-by`,
  getOfferEndpoint: (fundId: string) => `/funds/${fundId}/offers`,
  getDistrictsEndpoint: (provinceId: string) => `/provinces/${provinceId}/districts`,
  getFundsCorporationsActionDateEndpoint: (fundId: string) =>
    `/funds/${fundId}/corporations/action-date`,
  getFinancialDataDetailEndpoint: (financialDataId: string) =>
    `/sme-users/financial-data/${financialDataId}`,
  invoicePayId: (invoiceId: any) =>`/invoice/${invoiceId}/pay`,

  businessDay: (day: number, month: number, year: number) => `/business-days/${day}.${month}.${year}`,
  buyerValor: (buyerId: any) =>`/financial-institutions/buyers/${buyerId}/delay-value`,
  buyerLimit: (buyerId: any) =>`/financial-institutions/buyers/${buyerId}/limit`,
  cancelDiscountApplication: (discountapplication: any) =>`/discount-offer/${discountapplication}/cancel`,

  zendeskRequest: '/requests',

  getCreatefinancialOffers: (id: string)  => `/discount-offer/${id}/offer`, 

  getFinancialInstitution: (id: string) => `/financialinstitution/${id}/discount-application`,

  getDiscountOffer: (id: string) => `/discount-offer/${id}/reject`,

  getFinancialDetail: (id: string, discountId: string) =>
    `/discount-offer/${discountId}/financial-institution/${id}`,

  getFinancialOffers: (pageNumber: any, pageSize: any) =>
    `/discount-offer/financial-institution-discount-applications?pageNumber=${pageNumber}&pageSize=${pageSize}`,

  getFinansOfferEndpoint: (supplierId: any, id: string, invoiceId: string) =>
    `/supplier/${supplierId}/discountapplication/${invoiceId}/financialinstitution/${id}/accept`,  

  setMaturityRates: (maturity: any) => `/rates/maturity/${maturity}`, 
  sendTermsEmail: (financialInstitutionUserId: any) => `/financial-institution-users/${financialInstitutionUserId}/terms`,

};

export const apiV1 = new HttpService({baseURL: `${v1ApiUrls}`});
export const generalApi = new HttpService({ baseURL: `${generalUrl}` });


