import {createGlobalStyle} from 'styled-components';

/* - INDEX -
 * COLOR
 * SPACING
 * TEXT
 */

const GlobalStyle = createGlobalStyle`
  // COLOR

  body {
    margin: 0;
    font-family: 'Montserrat'!important;
}
  .primary{
    color: ${({theme}) => theme.colors.primary};
  }
  .secondary{
    color: ${({theme}) => theme.colors.secondary};
  }
  
  .background-fade{
    background: ${({theme}) => theme.colors.primaryFaded};
  }
  .background {
    background: ${({theme}) => theme.colors.background};
  }

  .shadow{
    box-shadow: ${({theme}) => theme.main.shadow};
  }
  .shadow-hover{
    box-shadow: ${({theme}) => theme.main.shadowHover};
  }

  .opacity-50{
    opacity: 0.5;
  }
  .opacity-40{
    opacity: 0.4;
  }

  .cursor-pointer{
    cursor: pointer;
  }
  .cursor-pointer span{
    font-size:14px;
    font-weight: 600 !important;
    color: rgb(13,15,123) !important;
  }
  .cursor-pointer svg{
    font-size:14px;
    font-weight: 600 !important;
    color: rgb(13,15,123) !important;
  }
  h1 {
    font-size: 16px  !important;
    font-weight: 500;
    color: rgb(13,15,123) !important;
}
h2,h3,h4{
  font-size: 14px  !important;
}
.ant-btn-primary {
  color: #fff;
  border-color: rgb(13,15,123) ;
  background: rgb(13,15,123) ;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
span{
  font-size: 13px !important;
}
  .hover-scale{
    transition: transform ${({theme}) => theme.main.defaultAnimation};
    
    &:hover{
      transform: scale(1.05);
    }
  }

  .overflow-visible{
    overflow: visible;
  }


  // SPACING
  .w-100{
    width: 100%;
  }
  .h-100{
    height: 100%;
  }
  .page-content-padding{
    padding-left: ${({theme}) => theme.pageContent.paddingHorizontal}px;
    padding-right: ${({theme}) => theme.pageContent.paddingHorizontal}px;
  }
  
  .d-block{
    display: block;
  }
  .d-inline{
    display: inline;
  }
  .d-flex{
    display: flex;
  }

  .flex-1{
    flex: 1;
  }
  .flex-2{
    flex: 1;
  }
  .flex-grow-1{
    flex-grow: 1;
  }

  .align-center{
    align-items: center;
  }
  .align-start{
    align-items: flex-start;
  }
  .align-end{
    align-items: flex-end;
  }

  .justify-center{
    justify-items: center;
  }
  .justify-start{
    justify-items: flex-start;
  }
  .justify-end{
    justify-items: flex-end;
  }

  .m{
    margin: ${({theme}) => theme.main.margin}px !important;
  }
  .m-0{
    margin: 0px !important;
  }
  .m-auto{
    margin:  auto !important;
  }
  .mx{
    margin-right: ${({theme}) => theme.main.margin}px !important;
    margin-left: ${({theme}) => theme.main.margin}px !important;
  }
  .ml{
    margin-left: ${({theme}) => theme.main.margin}px !important;
  }
  .mr{
    margin-right: ${({theme}) => theme.main.margin}px !important;
  }
  .my{
    margin-top: ${({theme}) => theme.main.margin}px !important;
    margin-bottom: ${({theme}) => theme.main.margin}px !important;
  }
  .my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mt{
    margin-top: ${({theme}) => theme.main.margin}px !important;
  }
  .mb{
    margin-bottom: ${({theme}) => theme.main.margin}px !important;
  }
  .m-big{
    margin: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mx-big{
    margin-right: ${({theme}) => theme.main.marginBig}px !important;
    margin-left: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mr-big{
    margin-right: ${({theme}) => theme.main.marginBig}px !important;
  }
  .ml-big{
    margin-left: ${({theme}) => theme.main.marginBig}px !important;
  }
  .my-big{
    margin-top: ${({theme}) => theme.main.marginBig}px !important;
    margin-bottom: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mb-big{
    margin-bottom: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mt-big{
    margin-top: ${({theme}) => theme.main.marginBig}px !important;
  }

  .p{
    padding: ${({theme}) => theme.main.padding}px !important;
  }
  .p-auto{
    padding: auto !important;
  }
  .p-0{
    padding: 0px !important;
  }
  .px{
    padding-right: ${({theme}) => theme.main.padding}px !important;
    padding-left: ${({theme}) => theme.main.padding}px !important;
  }
  .pl{
    padding-left: ${({theme}) => theme.main.padding}px !important;
  }
  .pr{
    padding-right: ${({theme}) => theme.main.padding}px !important;
  }
  .py{
    padding-top: ${({theme}) => theme.main.padding}px !important;
    padding-bottom: ${({theme}) => theme.main.padding}px !important;
  }
  .pt{
    padding-top: ${({theme}) => theme.main.padding}px !important;
  }
  .pb{
    padding-bottom: ${({theme}) => theme.main.padding}px !important;
  }
  .p-big{
    padding: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .px-big{
    padding-right: ${({theme}) => theme.main.paddingBig}px !important;
    padding-left: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pl-big{
    padding-left: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pr-big{
    padding-right: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .py-big{
    padding-top: ${({theme}) => theme.main.paddingBig}px !important;
    padding-bottom: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pb-big{
    padding-bottom: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pt-big{
    padding-top: ${({theme}) => theme.main.paddingBig}px !important;
  }

  .border-primary{
    border: 1px solid ${({theme}) => theme.colors.primary} 
  }


  // TEXT
  .bold{
    font-weight: 600 !important;
  }
  .thin{
    font-weight: 300;
  }
  .regular{
    font-weight: 400;
  }
  .light{
    color: ${({theme}) => theme.colors.light};
  }
  .dark{
    color: ${({theme}) => theme.colors.dark};
  }
  .text-primary{
    color: ${({theme}) => theme.colors.primary};
  }
  .text-center{
    text-align: center;
  }
  .text-r{
    text-align: right;
  }
  // IMAGE
  .cover {
    object-fit: cover;
  }

  .linkSpan {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    color: #1a73e8;
    margin-top: 5px;
    font-weight: 700;
  }
  // ANTD TODO: Configure these options in cracoConfig

  .all-select-table .ant-checkbox{ display: none;}

.invoice-none .ant-checkbox{ display: none;}
.invoice-none .ant-checkbox-wrapper { display: none;}
.row-hide{ display: none}
.input-width{ width:100% !important}
.input-my ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}

.input-my :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

.input-my::-ms-input-placeholder { /* Microsoft Edge */
  color: #000;
}
.rates-row{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.rates-row>div{
  padding:5px 0;
   width: 160px;
   text-align:center
}

.rates-row>div:nth-child(1){  
  font-size: 13px;
  font-weight: 500;
  color: #131112;
  text-align:center
}

.rates-row>div .ant-form-item{
  margin-bottom:0 !important
}
.rates-row>div button{
  width: 288px !important;
  background-color: #0d0f7b;
  border-radius: 8px;
  border:none
}
.rates-row-btn{
  margin-top:20px;
  margin-bottom:20px;
  width:100%;
  justify-content: center;
}
.rates-row-btn>div{;
  width:100%;
}
.rates-row-title{
  background-color: #fafbff;
  padding:8px;
  font-size: 14px;
  font-weight: 500;
  color: #131112;
}
.rates-row-title>div{
  padding:0;
}

.int-col{
  display: flex;
    justify-content: center;
}
.int-box{
  width: 90px
}
.filter-row{
  display:flex;
  justify-content: space-between;
  align-items: end;
}
.filter-row>div{
 margin-right:20px
}
.filter-row>div>span{
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #001444;
  padding-left:10px;
  padding-bottom:4px
 }
.filter-row button{
  border-radius: 8px;
  border: solid 1px #001444;
  background-color: #001444;
}
.filter-row-button{
  border-radius: 8px;
  border: solid 1px #001444;
  background-color: #001444;
  color:#fff;
  padding: 3px 6px;
}
.table-limit-input .ant-form-item{
  margin-bottom: 0
}
.table-limit-input .ant-input-number{
  border-radius: 10px
}
.filter-row .ant-picker{
  border-radius: 8px;
  border: solid 1px #001444;
  color:#001444;
  font-size: 13px;
}
.filter-row .ant-input{
  border-radius: 8px;
  border: solid 1px #001444;
  color:#001444;
  font-size: 13px;
  min-width:228px;
  box-shadow: none;
}
.filter-row  .ant-checkbox + span {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #001444;
}
.trans-drop .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active{
  // background:none !important;
  color:#0d0f7b;
  text-decoration:underline
}
#filter-box .ant-picker-focused{
  box-shadow:none !important
}
.ant-picker-large .ant-picker-input > input {
  font-size: 14px !important;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 14px;
}
@media only screen and (max-width: 991px) {
  .filter-row{
    display:block;
  }
}

.hide-arrow .ant-input-number-handler-wrap { 
  visibility: hidden;
}
.two-color-table .ant-table-tbody tr:nth-child(2n){
  background-color:#fafbff !important
}
.two-color-table .ant-table-tbody .ant-table-cell-fix-left, .ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
   background: none !important
}
.page-title  {
      
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.page-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: rgb(13, 15, 123);
}
.page-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 100%;
  max-width: 255px;
  background-color: rgb(13, 15, 123);
}
.header-menu button{
  background: rgb(255, 255, 255) !important;
  border: 0px !important;;
  box-shadow: none !important;
  border-radius: 0px !important; 
  padding: 0px !important;
  font-weight: 600 !important;
  margin-right: 40px !important;
  color: rgb(13, 15, 123) !important;
  user-select: none !important;
 
  font-size: 14px !important;
}
.header-menu button:hover
{

  border-top-style: initial !important;
  border-right-style: initial !important;
  border-left-style: initial !important;
  border-top-color: initial !important;
  border-right-color: initial !important;
  border-left-color: initial !important;
  border-image: initial !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(13, 15, 123) !important;
}

.page-title{
  font-weight: 600 !important ;
  color: rgb(13, 15, 123) !important ;
  font-size: 18px !important ;
}
.page-title  {
      
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.page-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: rgb(13, 15, 123);
}
.page-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 100%;
  max-width: 255px;
  background-color: rgb(13, 15, 123);
}
.ant-btn-link{
  font-weight: 600 !important;
  color: rgb(13, 15, 123) !important;
  font-size: 14px !important;
}
.ant-table.ant-table-middle {
  font-size: 13px;
}
main>div{
  margin-top:40px !important;
  margin-bottom:40px !important;
}
.hamburger{
  cursor: pointer;
}
.hamburger,
.hamburger::before,
.hamburger::after {
  content:'';
  display: block;
  background: #0d0f7b;
  height: 3px;
  width: 1.75em;
  border-radius: 2px;
  transition: all ease-in-out 500ms;
  cursor: pointer;
}


.hamburger::before {
  transform: translateY(-7px);
}

.hamburger::after {
  transform: translateY(4px);
}


.open .hamburger {
  transform: rotate(45deg);
  background: #0d0f7b;
}

.open .hamburger::before {
  opacity: 0;            
}

.open .hamburger::after {
  transform: translateY(-3px) rotate(-90deg);
  background: #0d0f7b;
}
.menu-toggle {
  padding: 1em;
  position: absolute;
  top: 2.5em;
  right: 1em;
  cursor: pointer;
  display: none;
}
@media screen and (max-width: 1200px) {
  .menu-toggle{
      display: block;
  }
  .custom-menu{
    display:none
  }
}
.mobil-userName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  color: rgb(13, 15, 123) !important;
}
.mobil-userName .left{
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 800;
 
}
.mobil-userName p{
 margin-bottom: 0;
 color: rgb(13, 15, 123) !important;
 font-size: 14px;
    font-weight: 500;
}
.mobil-menu {
  margin-bottom: 0;
  margin-top: 50px;
  display: flex;
    flex-direction: column;
}
.mobil-menu .ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  background-color: #fff;
  border: none;
}
.mobil-menu  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px;
  color:  rgb(13, 15, 123) !important;
  line-height: 1;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  padding-left: 0;
}
.mobil-menu .ant-collapse-content {
  color:  rgb(13, 15, 123) !important;
  background-color: #fff;
  border-top: none
}
.mobil-menu .ant-collapse > .ant-collapse-item {
  border-bottom:none;
}
.mobil-menu  span {
  font-size: 14px !important;
  color:  rgb(13, 15, 123) !important;
  font-weight: 500;
}
.mobil-menu a {
  line-height: 40px !important;
}
.mobil-menu .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0;
}
  display: flex;
    flex-direction: column;
}
.mobil-menu  a {
  font-size: 14px !important;
  color:  rgb(13, 15, 123) !important;
  font-weight: 500;
}
.mobil-menu  a{
  line-height: 45px;
}
.mobil-menu button{
  background: rgb(255, 255, 255) !important;
  border: 0px !important;;
  box-shadow: none !important;
  border-radius: 0px !important; 
  padding: 0px !important;
  font-weight: 600 !important;
  margin-right: 40px !important;
  color: rgb(13, 15, 123) !important;
  user-select: none !important;
  font-size: 14px !important;
  margin: 5px;
}
.mobil-menu button:hover{
  border-width: 0px 0px 2px !important; 
  border-top-style: initial !important;
  border-right-style: initial !important;
  border-left-style: initial !important;
  border-top-color: initial !important;
  border-right-color: initial !important;
  border-left-color: initial !important;
  border-image: initial !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(13, 15, 123) !important;
}
.my-account-box{
  font-size: 18px;
  font-weight: 600;
  text-align: inherit;
  font-style: initial;
  color: rgb(13, 15, 123);
}
.my-account-icon {
  font-size: 16px;
  font-weight: 900;
  text-align: inherit;
  font-style: initial;
  color: rgb(13, 15, 123);
  width: 16px;
}
.ant-table{
  font-size:13px
}
.mobil-menu .ant-collapse-content > .ant-collapse-content-box {
  display: flex;
  flex-direction: column;

}
.modal-login-logout .ant-modal-close-x{
  display: none !important;
}
.modal-login-logout  .ant-btn{
  color:#fff !important;
}
.modal-login-logout .ant-modal-footer{
  text-align: center;
    border-top: none;
}
.modal-login-logout .ant-modal-body {
  padding-bottom: 10px;
}
.modal-login-logout .ant-modal-content {
  border-radius: 6px;
}
.two-color-table .ant-table-tbody tr td:nth-child(1)  {
  z-index: 99;
  background: #fafbff !important;
}

.contract-modal {
  margin-bottom:15px;
  margin-top:15px
}

.contract-modal-style  {
  width: 800px !important;
  height:500px  !important
}
.contract-modal-style .ant-modal-body{
  padding-right: 5px !important;
}
.contract-modal-style .ant-tabs-nav{
  margin: 0 auto;
}
.contract-modal button{
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 0 4px;
  border: none;
  height: auto !important;
}
.contract-modal button span{
  text-decoration: underline;
  color: #40a9ff;
  border-color: #40a9ff;
}
.register-btn span{
  color:#000 !important 
}
.contract-modal-style .ant-tabs-content-holder{
  height: 500px;
  overflow-y: scroll;
}

.membership-modal  .title-center{
  text-align:center;
  margin-top: 30px;
}
.contract-modal-style  .ant-tabs-tabpane{
  padding-right: 14px;
}
.membership-modal  ul{
  margin-top:5px;
  margin-bottom:15px
}
.membership-modal ol{
  margin-left:8px;
  padding-left: 20px;
}
.membership-modal table td{
  border:1px solid;
  padding:4px
}
.membership-modal .top-li {
  margin-top:15px
}
.membership-modal li::marker{
  font-weight:600;
}
.membership-modal  span{
  font-weight:600;
  margin-bottom:10px

}
.membership-modal  p{
 text-align: justify;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.selectInvoice-box{
  max-height: 100px;
 overflow-y: scroll;
    padding-bottom: 10px;
  margin-bottom:20px;
  width: 95%;
}

.ant-spin-nested-loading > div > .ant-spin {
  position: fixed;
  top: 20%;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text{
  font-weight: 700;
    font-size: 16px;
    letter-spacing: 2px;
}
.new-line {
  white-space: pre-line;
  
}
.marginBottom{
  margin-bottom:2px;
  font-size:13px;
  border:1px solid #f0f0f0;
  padding:5px
}
.rates-row-title span{
  font-size:12px
}


.daily-rate-delete-btn{
  color: rgb(255, 0, 0);
    font-size: 20px !important;
}
.daily-rate-add-btn{
  margin-bottom: 254px;
  margin-left: -25px;
  font-size: 20px !important;
}
.ant-popover-inner-content {
  max-width: 400px}
.change-password .ant-form-item{
  margin-bottom: 14px;
}

@media only screen and (max-width: 740px) {
  .interest-rates-box{
    max-width: 600px;
    overflow: auto;
  }
}
`;

export default GlobalStyle;
